import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { KIOSK_AUTO_LOGIN, redirection_after_order } from '../common/api';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  terminalid = '1'
  constructor(private kioskauthservice: KioskauthService,
    private store: LocalStoreService

  ) {

  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isLoggedIn = this.kioskauthservice.is_logged_in();
    if (isLoggedIn) {

      return true;
    } else {
      let Autologin = KIOSK_AUTO_LOGIN
      this.store.hardlogout()
      location.href = redirection_after_order;
      return false;
    }
  }


}
